<i18n>
ru:
  additionalLogosBlockText: >-
    Успешное кафе, это то, в котором весь персонал работает оперативно. Важны
    все моменты: от приготовления еды до доставки ее клиенту. Довольный клиент –
    это результат слаженной работы каждого человека в ресторане. Если вы хотите
    стать частью профессиональной команды и начать работу в ресторанном бизнесе,
    то мы ждем тебя!
  additionalLogosBlockTitle: Добро пожаловать в нашу команду!
  chipsTitle: Вакансии
  contacts: 'Контакты:'
  details: Подробнее
  experience: 'Требуемый опыт работы:'
  joinOurTeam: Присоединяйтесь к нашей команде
  notSpecified: Не указано
  noVacanciesNow: >-
    Мы ценим ваше желание работать в нашей команде, но на данный момент у нас
    нет открытых вакансий. Следите за обновлениями в данном разделе
  ourVacancies: Наши вакансии
  requirements: 'Требования:'
  respond: Откликнуться
  responsibilities: 'Обязанности:'
  sliderTitle: Выберите интересующую вакансию
  workSchedule: 'График работы:'
ua:
  additionalLogosBlockText: >-
    Успішне кафе - це те, де весь персонал працює оперативно. Важливі всі
    деталі: від приготування їжі до доставки її клієнту. Задоволений клієнт - це
    результат слаженої роботи кожної людини в ресторані. Якщо ви хочете стати
    частиною професійної команди та почати працювати у ресторанному бізнесі, то
    ми чекаємо на тебе!
  additionalLogosBlockTitle: Ласкаво просимо до нашої команди!
  chipsTitle: Вакансії
  contacts: 'Контакти:'
  details: Детальніше
  experience: 'Необхідний досвід роботи:'
  joinOurTeam: Приєднуйтесь до нашої команди
  notSpecified: Не вказано
  noVacanciesNow: >-
    Ми цінуємо ваше бажання працювати в нашій команді, але наразі у нас немає
    відкритих вакансій. Слідкуйте за оновленнями в цьому розділі
  ourVacancies: Наші вакансії
  requirements: 'Вимоги:'
  respond: Відгукнутися
  responsibilities: 'Обов’язки:'
  sliderTitle: Оберіть цікаву вакансію
  workSchedule: 'Графік роботи:'
us:
  additionalLogosBlockText: >-
    A successful cafe is one where the entire staff works efficiently. Every
    detail matters: from preparing food to delivering it to the customer. A
    satisfied customer is the result of the coordinated work of everyone in the
    restaurant. If you want to be part of a professional team and start working
    in the restaurant business, then we are waiting for you!
  additionalLogosBlockTitle: Welcome to our team!
  chipsTitle: Vacancies
  contacts: 'Contacts:'
  details: Details
  experience: 'Required work experience:'
  joinOurTeam: Join our team
  notSpecified: Not specified
  noVacanciesNow: >-
    We appreciate your interest in joining our team, but currently we don't have
    any open positions. Please check this section for updates.
  ourVacancies: Our vacancies
  requirements: 'Requirements:'
  respond: Respond
  responsibilities: 'Responsibilities:'
  sliderTitle: Choose the vacancy you are interested in
  workSchedule: 'Working hours:'
</i18n>

<template>
  <section
    v-if="pageStore.Vacancies.length === 0"
    class="v-no-vacancies v-d-flex v-justify-content-center v-align-items-center v-mb-xl"
  >
    <div class="v-container">
      <div class="v-d-flex v-flex-column v-align-items-center">
        <icon-old-general-handshake class="v-no-vacancies--icon" />
        <div
          class="v-no-vacancies--text"
          v-html="translate('vacanciesList.noVacanciesNow')"
        />
      </div>
    </div>
  </section>
  <section
    v-else
    class="v-vacancies-list v-mb-xl"
  >
    <div class="v-container">
      <div
        v-if="
          isDesktopVersion &&
          imageExists(appConfig.VueSettingsPreRun.VacanciesBannerDesktop)
        "
        class="v-vacancies-list--intro-banner v-mb-md"
      >
        <arora-nuxt-image
          preload
          :alt="translate('vacanciesList.additionalLogosBlockTitle')"
          :image="appConfig.VueSettingsPreRun.VacanciesBannerDesktop"
        />
      </div>
      <div
        v-else-if="
          isExtraSmall && imageExists(appConfig.VueSettingsPreRun.VacanciesBannerMobile)
        "
        class="v-vacancies-list--intro-banner v-mb-md"
      >
        <arora-nuxt-image
          preload
          :alt="translate('vacanciesList.additionalLogosBlockTitle')"
          :image="appConfig.VueSettingsPreRun.VacanciesBannerMobile"
        />
      </div>
      <div
        v-else-if="imageExists(appConfig.VueSettingsPreRun.VacanciesBannerTablet)"
        class="v-vacancies-list--intro-banner v-mb-md"
      >
        <arora-nuxt-image
          preload
          :alt="translate('vacanciesList.additionalLogosBlockTitle')"
          :image="appConfig.VueSettingsPreRun.VacanciesBannerTablet"
        />
      </div>
      <div
        class="v-vacancies-list--intro-title v-title v-mb-xs"
        v-html="translate('vacanciesList.additionalLogosBlockTitle')"
      />
      <div
        class="v-vacancies-list--intro-subtitle v-mb-xl"
        v-html="translate('vacanciesList.additionalLogosBlockText')"
      />
    </div>
    <div
      v-if="
        appConfig.VueSettingsPreRun.VacanciesShowSlider && pageStore.Vacancies.length > 2
      "
      class="v-vacancies-list--intro-slider v-mb-xl"
      :class="[isSmall ? 'v-container-no-gutter' : 'v-container']"
    >
      <div class="v-row v-vacancies-list--intro-slider--wrapper">
        <div
          class="v-vacancies-list--intro-slider--title v-col-12 v-mb-sm"
          v-html="translate('vacanciesList.sliderTitle')"
        />
        <div class="v-col-12 v-col-md-11 v-vacancies-list--intro-slider--swiper">
          <arora-swiper-slider
            disable-breakpoints
            enable-navigation
            loop
            :autoplay-delay="3000"
            :effect="isSmall ? 'slide' : 'coverflow'"
            :items="vacanciesList"
            :max-items="isSmall ? 1.2 : 3"
            :show-part-of-next-slide="false"
            :space-between="isSmall ? 8 : 35"
            pagination-type="none"
          >
            <template #item="vacancy: Vacancy">
              <div class="v-vacancy-slide v-d-flex v-flex-column">
                <span
                  class="v-vacancy-slide--title v-title v-mb-sm"
                  v-html="sanitize(vacancy.Title)"
                />
                <div class="v-vacancy-slide--button">
                  <arora-button
                    v-if="
                      appConfig.VueSettingsPreRun.VacanciesEnableEmploymentForm ||
                      !stringIsNullOrWhitespace(
                        appConfig.VueSettingsPreRun.VacanciesExternalButtonLink
                      )
                    "
                    :label="translate('vacanciesList.respond')"
                    @click="() => respondTo(vacancy.ID)"
                  />
                </div>
                <div class="v-vacancy-slide--image">
                  <arora-nuxt-image
                    disable-lazy
                    disable-resize
                    :alt="sanitize(vacancy.Title)"
                    :image="vacancy.ImageSlider"
                  />
                </div>
              </div>
            </template>
          </arora-swiper-slider>
        </div>
      </div>
    </div>

    <div class="v-container">
      <div
        class="v-vacancies-list--title v-title v-mb-sm"
        v-html="translate('vacanciesList.ourVacancies')"
      />
      <common-cards-flex-mesh
        class-name="v-overflow-hidden"
        :items="pageStore.Vacancies"
        :max-items="haveAnyImage ? 1 : 2"
        :mesh-gap="16"
      >
        <template #item="vacancy: Vacancy">
          <div
            :key="vacancy.ID"
            class="v-vacancy"
            :class="{ 'v-vacancy__have-image': haveAnyImage }"
          >
            <div
              v-if="haveAnyImage"
              :class="`v-vacancy--image v-vacancy--image__${appConfig.VueSettingsPreRun.VacanciesImagesFormat}`"
            >
              <div
                v-if="
                  imageExists(vacancy.Image) &&
                  isSmall &&
                  appConfig.VueSettingsPreRun.VacanciesImagesFormat === 'round'
                "
                class="v-vacancy--image__round--title v-subtitle"
                v-html="sanitize(vacancy.Title)"
              />
              <arora-nuxt-image
                v-if="imageExists(vacancy.Image)"
                :alt="sanitize(vacancy.Title)"
                :image="vacancy.Image"
              />
              <icon-old-general-case v-else />
            </div>
            <dl
              class="v-vacancy--content"
              :class="{ 'v-vacancy--content__have-image': haveAnyImage }"
            >
              <div
                v-if="
                  !(
                    imageExists(vacancy.Image) &&
                    isSmall &&
                    appConfig.VueSettingsPreRun.VacanciesImagesFormat === 'round'
                  )
                "
                class="v-vacancy--title v-subtitle"
                :class="[
                  appConfig.VueSettingsPreRun.VacanciesShowSalary ? 'v-mb-xxs' : 'v-mb-xs'
                ]"
                v-html="sanitize(vacancy.Title)"
              />
              <template v-if="appConfig.VueSettingsPreRun.VacanciesShowSalary">
                <div
                  v-if="stringIsNullOrWhitespace(vacancy.Pay)"
                  class="v-vacancy--subtitle"
                  v-html="translate('vacanciesList.notSpecified')"
                />
                <div
                  v-else-if="Number.isNaN(+vacancy.Pay)"
                  class="v-vacancy--subtitle v-mb-xs"
                  v-html="capitalLetter(sanitize(vacancy.Pay), true)"
                />
                <div
                  v-else
                  class="v-vacancy--subtitle v-mb-xs"
                >
                  <common-currency :amount="+vacancy.Pay" />
                </div>
              </template>
              <div
                v-if="!stringIsNullOrWhitespace(vacancy.Description)"
                class="v-vacancy--description v-mb-sm"
                v-html="sanitize(vacancy.Description)"
              />
              <div
                v-if="appConfig.VueSettingsPreRun.VacanciesShowSchedule"
                class="v-vacancy--additional"
                :class="[
                  appConfig.VueSettingsPreRun.VacanciesShowExperience
                    ? 'v-mb-xxs'
                    : 'v-mb-sm'
                ]"
              >
                <dt
                  class="v-mr-xxs"
                  v-html="translate('vacanciesList.workSchedule')"
                />
                <dd
                  v-if="stringIsNullOrWhitespace(vacancy.Schedule)"
                  class="v-body-text-color-light"
                  v-html="translate('vacanciesList.notSpecified')"
                />
                <dd
                  v-else
                  class="v-body-text-color-light"
                  v-html="sanitize(vacancy.Schedule)"
                />
              </div>
              <div
                v-if="appConfig.VueSettingsPreRun.VacanciesShowExperience"
                class="v-vacancy--additional"
                :class="[
                  appConfig.VueSettingsPreRun.VacanciesShowContacts
                    ? 'v-mb-xxs'
                    : 'v-mb-sm'
                ]"
              >
                <dt
                  class="v-mr-xxs"
                  v-html="translate('vacanciesList.experience')"
                />
                <dd
                  v-if="stringIsNullOrWhitespace(vacancy.Experience)"
                  class="v-body-text-color-light"
                  v-html="translate('vacanciesList.notSpecified')"
                />
                <dd
                  v-else
                  class="v-body-text-color-light"
                  v-html="sanitize(vacancy.Experience)"
                />
              </div>
              <div
                v-if="appConfig.VueSettingsPreRun.VacanciesShowContacts"
                class="v-vacancy--additional v-mb-sm"
              >
                <dt
                  class="v-mr-xxs"
                  v-html="translate('vacanciesList.contacts')"
                />
                <dd
                  v-if="stringIsNullOrWhitespace(vacancy.Contacts)"
                  class="v-body-text-color-light"
                  v-html="translate('vacanciesList.notSpecified')"
                />
                <address
                  v-else
                  class="v-body-text-color-light"
                  v-html="sanitize(vacancy.Contacts)"
                />
              </div>
              <div
                v-if="appConfig.VueSettingsPreRun.VacanciesShowResponsibilities"
                class="v-vacancy--require v-mb-sm"
              >
                <dt
                  class="v-vacancy--require-title v-mb-xxs"
                  v-html="translate('vacanciesList.responsibilities')"
                />
                <dd
                  v-if="stringIsNullOrWhitespace(vacancy.Responsibilities)"
                  v-html="translate('vacanciesList.notSpecified')"
                />
                <dd
                  v-else
                  v-html="sanitize(vacancy.Responsibilities)"
                />
              </div>
              <div
                v-if="appConfig.VueSettingsPreRun.VacanciesShowRequirements"
                class="v-vacancy--require v-mb-sm"
              >
                <dt
                  class="v-vacancy--require-title v-mb-xxs"
                  v-html="translate('vacanciesList.requirements')"
                />
                <dd
                  v-if="stringIsNullOrWhitespace(vacancy.Requirements)"
                  v-html="translate('vacanciesList.notSpecified')"
                />
                <dd v-html="sanitize(vacancy.Requirements)" />
              </div>
            </dl>

            <div class="v-vacancy--buttons">
              <div class="v-vacancy--buttons--default">
                <arora-button
                  v-if="
                    appConfig.VueSettingsPreRun.VacanciesEnableEmploymentForm ||
                    !stringIsNullOrWhitespace(
                      appConfig.VueSettingsPreRun.VacanciesExternalButtonLink
                    )
                  "
                  :class-name="!isSmall ? 'v-mr-sm' : ''"
                  :label="translate('vacanciesList.respond')"
                  @click="() => respondTo(vacancy.ID)"
                />
                <arora-button
                  class-name="v-btn-border"
                  :label="translate('vacanciesList.details')"
                  @click="() => openDetailsPopup(vacancy.ID)"
                />
              </div>

              <div class="v-d-flex v-flex-row v-align-items-center">
                <arora-nuxt-link
                  v-for="(vacancyLink, index) in externalLinksVacancy"
                  :key="`vacancy-${vacancy.ID}-${index}`"
                  class-name="v-external-link-button v-mr-xs v-pointer"
                  external-link
                  open-in-new-tab
                  :href="vacancyLink.Url"
                  :label="vacancyLink.Url"
                >
                  <arora-nuxt-image
                    :alt="sanitize(vacancyLink.Url)"
                    :height="36"
                    :image="vacancyLink.Image"
                    :width="36"
                  />
                </arora-nuxt-link>
              </div>
            </div>
          </div>
        </template>
      </common-cards-flex-mesh>
      <page-vacancies-form
        v-if="
          appConfig.VueSettingsPreRun.VacanciesEnableEmploymentForm &&
          stringIsNullOrWhitespace(
            appConfig.VueSettingsPreRun.VacanciesExternalButtonLink
          )
        "
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import type { Vacancy } from '~types/pageStore'
import type { ExternalLink } from '~types/settingsVariants'

import { type GUID, useCommon, useWindowSize } from '@arora/common'

const pageStore = usePageStore()
const popupStore = usePopupStore()

const appConfig = useAppConfig()
const { sanitize, translate } = useI18nSanitized()
const { capitalLetter, stringIsNullOrWhitespace } = useCommon()
const { imageExists } = useImageInfo()
const { isDesktopVersion, isExtraSmall, isSmall } = useWindowSize()

const haveAnyImage = computed<boolean>(() =>
  pageStore.Vacancies.some((v) => imageExists(v.Image))
)

const vacanciesList = computed<Vacancy[]>(() =>
  pageStore.Vacancies.length <= 3
    ? pageStore.Vacancies.concat(pageStore.Vacancies)
    : pageStore.Vacancies
)

const externalLinksVacancy = computed<ExternalLink[]>(() =>
  appConfig.VueSettingsPreRun.ExternalLinks.filter((l) => l.Type === 'vacancy').sort(
    (a, b) => a.SortWeight - b.SortWeight
  )
)

onMounted(() => {
  if (import.meta.client) pageStore.loadVacancies()
})

function respondTo(vacancyId: GUID): void {
  if (stringIsNullOrWhitespace(appConfig.VueSettingsPreRun.VacanciesExternalButtonLink)) {
    pageStore.SelectedVacancyId = vacancyId

    const element = document.querySelector('#v-vacancy-form')

    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  } else {
    navigateTo(appConfig.VueSettingsPreRun.VacanciesExternalButtonLink, {
      external: true
    })
  }
}

function openDetailsPopup(vacancyId: GUID): void {
  popupStore.openPopup({
    popupClosable: true,
    popupName: 'vacancyPopup',
    popupProperties: new Map<string, unknown>([['id', vacancyId]])
  })
}
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-vacancies-list {
  &--intro-title {
    font-weight: 600;
    font-size: 2rem;
  }
  &--intro-subtitle {
    font-size: 1.35rem;
  }
  &--title {
    font-weight: 600;
    font-size: variables.$TextSizeH2;
  }
  &--intro-banner {
    .v-img-fluid {
      border-radius: variables.$BorderRadius;
      width: 100%;
    }
  }

  &--intro-slider {
    @include mixins.swiper-button-default-big;

    &--title {
      font-weight: 600;
      font-size: variables.$TextSizeH2;
      text-align: center;
    }

    &--wrapper {
      max-width: 100%;
    }

    &--swiper {
      //half column on left
      margin-left: 4.16666666%;

      @include mixins.sm {
        margin: 0 0 0 15px;
      }
    }

    .v-swiper-wrapper {
      margin-left: auto;
      margin-right: auto;

      .swiper-slide {
        width: fit-content;
      }
    }

    .v-vacancy-slide {
      background: variables.$BodyElementsBackground;
      border-radius: variables.$BorderRadius;
      box-shadow: variables.$CardShadow;

      width: 100%;

      padding-top: 1.425rem;

      &--title {
        font-size: 1.425rem;
        color: variables.$PrimaryBackgroundColor;
        text-align: center;
        font-weight: 600;
      }

      &--button {
        text-align: center;
      }

      &--image {
        padding: 20px;
        text-align: center;

        img {
          border-radius: calc(variables.$BorderRadius - 20px);
        }
      }
    }
  }
}

.v-vacancy {
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-areas:
    'desc'
    'buttons';
  grid-template-rows: auto 2.75rem;
  grid-template-columns: 1fr;
  padding: 20px;

  @include mixins.md {
    height: auto;
    grid-template-columns: 1fr;
    grid-template-rows: auto 130px;
  }

  &__have-image {
    height: 320px;
    padding: 0;
    grid-template-columns: 320px 1fr;
    grid-template-rows: 240px 3.5rem;
    gap: 20px 16px;
    grid-template-areas:
      'image desc'
      'image buttons';

    @include mixins.md {
      height: auto;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto 160px;
      grid-template-areas:
        'image'
        'desc'
        'buttons';
    }
  }

  &--content {
    grid-area: desc;

    padding-right: 1rem;

    &__have-image {
      padding-top: 20px;
      overflow: hidden;

      @include mixins.md {
        padding: 0 20px 0;
      }

      &:after {
        content: ' ';
        position: absolute;
        bottom: 80px;
        right: 0;
        height: 25px;
        top: auto;
        left: 320px;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          variables.$BodyElementsBackground 100%
        );
        z-index: 1;

        @include mixins.md {
          background: none;
        }
      }
    }
  }

  &--buttons {
    grid-area: buttons;

    display: flex;
    padding-bottom: 20px;
    flex-direction: row;
    height: 2.5rem;

    &--default {
      display: flex;
      margin-right: 1.75rem;
      height: 2.5rem;

      @include mixins.md {
        width: 100%;
        height: auto;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 20px;
        margin-right: 0;
      }
    }

    @include mixins.md {
      padding: 0 20px 0;
      height: auto;
      flex-direction: column-reverse;
      align-items: flex-end;
      gap: 1rem;
    }

    .v-external-link-button {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: variables.$BodyElementsBackgroundOpaq25;
      border-radius: 50%;
    }
  }

  &--image {
    height: 320px;
    width: 320px;
    text-align: center;

    grid-area: image;

    background: variables.$BodyBackgroundDarken10;
    color: variables.$BodyBackgroundDarken15;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 140px;
      height: 140px;
    }

    &__round {
      &--title {
        font-size: 1.4rem;
        font-weight: 600;
        color: variables.$BodyTextColor;
        margin: 1rem 0;
      }

      @include mixins.md {
        background: variables.$BodyElementsBackground;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;

        img {
          width: calc(100% - 12rem);
          border-radius: 50%;
          overflow: hidden;
        }
      }

      @include mixins.sm {
        img {
          width: calc(100% - 8rem);
        }
      }

      @include mixins.xs {
        img {
          width: calc(100% - 4rem);
        }
      }
    }

    @include mixins.md {
      height: auto;
      width: 100%;
      min-height: 320px;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    @include mixins.sm {
      padding: 0;
    }
  }

  &--title {
    font-size: 1.4rem;
    font-weight: 600;
  }

  &--subtitle {
    font-size: 1.25rem;
    color: variables.$BodyTextColorLight;

    p {
      margin: 0;
    }
  }

  &--description {
    p {
      margin-top: 0;
    }
  }

  &--additional {
    display: flex;
  }

  &--require {
    &-title {
      font-size: 1.1rem;
      font-weight: 600;
    }
    p,
    ul {
      margin: 0;
    }
  }
}

.v-no-vacancies {
  text-align: center;
  font-size: variables.$TextSizeH2;
  color: variables.$BodyTextColorLight;

  &--text {
    max-width: 900px;
  }

  &--icon {
    width: 200px;
    height: 160px;
    fill: variables.$BodyTextColorLight;
  }
}
</style>
